var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"text-center"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Data "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"headers":_vm.headers,"items":_vm.getReviewStatus(),"search":_vm.search,"item-class":_vm.getColor,"items-per-page":50,"options":_vm.options,"footer-props":{
            itemsPerPageOptions: [
              5,
              50,
              100,
              {
                text: 'All',
                value: -1,
              } ],
          }},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"headers",fn:function(props){return [_c('tr',_vm._l((props.headers),function(header,i){return _c('th',{key:i,attrs:{"width":header.width},domProps:{"innerHTML":_vm._s(header.text)}})}),0)]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){return _vm.reviewBatch(item)}}},[_vm._v("Review")])]}}],null,true)})],1)],1)],1),_c('review',{attrs:{"method":_vm.updateTableRaw,"batchDetails":_vm.batchToEdit}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }