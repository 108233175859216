<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col
        cols="6"
        md="3"
        align-self="center"
        class="mr-10 pr-5 pl-4 pt-5 align-center"
      >
        <v-select
          v-if="showProjects"
          :items="projects"
          v-model="selectedProjects"
          item-text="name"
          item-value="id"
          label="Select projects"
          dense
          outlined
          clearable
          multiple
          :menu-props="{
            maxHeight: '300',
            closeOnContentClick: selects.projectSelect,
          }"
          @click:clear="clearProjects()"
          @blur="projectChanged()"
        >
          <template v-slot:append-item>
            <v-divider class="mb-2"></v-divider>
            <v-list-item>
              <v-list-item-action>
                <v-btn
                  class="Okbtn"
                  larg
                  @click="projectChanged()"
                  style="background-color:#1976d2; color:white;"
                  >Ok</v-btn
                >
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-select>
        <v-select
          v-if="showAllWaves"
          :items="allUniqueWaveNames"
          v-model="selectedWaves"
          item-text="name"
          item-value="id"
          label="Select waves"
          dense
          outlined
          clearable
          multiple
          :menu-props="{
            maxHeight: '300',
            closeOnContentClick: selects.waveSelect,
          }"
          @click:clear="clearAllWaves()"
          @blur="waveChanged()"
        >
          <template v-slot:append-item>
            <v-divider class="mb-2"></v-divider>
            <v-list-item>
              <v-list-item-action>
                <v-btn
                  class="Okbtn"
                  larg
                  @click="waveChanged()"
                  style="background-color:#1976d2; color:white;"
                  >Ok</v-btn
                >
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-select>
        <v-select
          v-if="showBasketType"
          :items="basketTypes"
          v-model="selectedBasketTypes"
          item-text="name"
          item-value="id"
          label="Select basket types"
          dense
          multiple
          :menu-props="{
            maxHeight: '300',
            closeOnContentClick: selects.basketTypeSelect,
          }"
          @click:clear="clearBasketType()"
          @blur="basketTypeChanged()"
          clearable
          outlined
        >
          <template v-slot:append-item>
            <v-divider class="mb-2"></v-divider>
            <v-list-item>
              <v-list-item-action>
                <v-btn
                  class="Okbtn"
                  larg
                  @click="basketTypeChanged()"
                  style="background-color:#1976d2; color:white;"
                  >Ok</v-btn
                >
              </v-list-item-action>
            </v-list-item>
          </template></v-select
        >
        <v-select
          v-if="showBasketAndWave && showWave"
          :items="uniqueWaveNames"
          v-model="selectedWaves"
          item-text="name"
          item-value="id"
          label="Select wave"
          dense
          multiple
          @click:clear="clearWaves()"
          clearable
          outlined
        ></v-select>
        <v-select
          v-if="showBasketAndWave"
          :items="baskets"
          v-model="selectedBaskets"
          item-text="name"
          item-value="id"
          label="Select basket"
          dense
          hide-details="auto"
          clearable
          multiple
          outlined
          @blur="basketChanged()"
        ></v-select>
        <v-row no-gutters>
          <v-col class="btnGroup mr-3">
            <v-btn
              class="btnGroup"
              style="backgroundColor:orange"
              @click="recalculeteProgressData()"
              >Recalculate</v-btn
            >
          </v-col>
          <v-col class="btnGroup">
            <v-btn class="btnGroup" depressed @click="clearFilters()"
              >Clear</v-btn
            >
          </v-col>
          <v-col>
            <v-switch
              v-model="includingTestData"
              :label="'Including test data'"
              @change="toggleIncludeIgnoreDataForAnalysesAndExports()"
            ></v-switch>
          </v-col>
        </v-row>
        <v-row no-gutters class="mb-5 ">
          <v-col class="btnGroup mr-3">
            <v-btn
              outlined
              class="btnGroup"
              color="primary"
              @click="exportCsvfile(true)"
              >Export CSV</v-btn
            >
          </v-col>
          <v-col class="btnGroup">
            <v-btn
              class="btnGroup"
              color="primary"
              outlined
              @click="exportCsvfile(false)"
              >Export XLSX</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="6" md="8" class="pl-10 pt-5 ">
        <p v-if="chartData.length === 0" class="pt-10 pb-10 text-center">
          Please select a project or a basket if you want to filter results to a
          particular project or basket.
        </p>
        <div id="barChart"></div>
      </v-col>
    </v-row>
    <div class="pl-4">
      Last time synchronized at {{ lastTimeSynchronizedAt }}
    </div>
    <table-component
      :updateTableRaw="updateTableRaw"
      :tableData="progressData"
      :totalBatches="totalBatches"
    ></table-component>
  </v-container>
</template>

<script>
/* eslint-disable no-debugger */
import bb, { bar } from "billboard.js";
import axios from "axios";
import "billboard.js/dist/theme/insight.css";
import "billboard.js/dist/billboard.css";
import TableComponent from "../components/TableComponent";
import { mapMutations, mapActions, mapGetters } from "vuex";
import fileDownload from "js-file-download";

const baseUrl = process.env.VUE_APP_API_URL;
const disabledServerSidePaging = true;

export default {
  name: "report",
  components: { TableComponent },
  data: () => ({
    lastTimeSynchronizedAt: null,
    showBasketType: false,
    showBasketAndWave: false,
    showProjects: true,
    showAllWaves: true,
    showWave: true,
    selectedBaskets: [],
    selectedProjects: [],
    selectedBasketTypes: [],
    selectedWaves: [],
    includingTestData: false,
    selects: {
      projectSelect: false,
      basketTypeSelect: false,
      waveSelect: false,
    },
    chart: null,
    chartNames: [],
    tableData: [],
    baskets: [],
    waves: [],
    uniqueWaveNames: [],
    uniqueWavesObject: {},
    allWaves: [],
    allUniqueWaveNames: [],
    allUniqueWavesObject: {},
    filters: [],
    basketTypes: [],
    projects: [],
    chartData: [],
    progressData: [],
    totalBatches: 0,
    chart_config: {
      key: "Basket",
      values: ["TasksCompleted", "TasksSkipped", "TasksIncomplete"],
      //orientation: "horizontal",
      axis: {
        yTicks: 4,
      },
      labelRotation: 250,
      color: {
        keys: {
          TasksCompleted: "#41b882",
          TasksSkipped: "#F26643",
          TasksIncomplete: "#848584",
        },
      },
    },
    colors: {
      "Tasks Completed": "#41b882",
      "Tasks Skipped": "#F26643",
      "Tasks Incomplete": "#848584",
    },
  }),
  async mounted() {
    await this.getProjectFilters();
    // await this.getFieldworkMonitoringSystemProgress();
  },
  computed: {
    ...mapGetters(["options"]),
  },
  methods: {
    ...mapMutations([
      "SET_SHOW_ERROR_MESSAGE",
      "ADD_ASYNC_REQUEST",
      "REMOVE_ASYNC_REQUEST",
    ]),
    ...mapActions(["loginRedirect"]),

    showErrorDialog(message) {
      this.SET_SHOW_ERROR_MESSAGE({
        value: true,
        message: message,
      });
    },
    clearCharts() {
      var charts = document.getElementsByClassName("bb");
      if (charts.length > 0) {
        for (let i = 0; i < charts.length; i++) {
          const element = charts[i];
          if (
            element.attributes.class.nodeValue == "bb" &&
            !element.attributes.id
          ) {
            charts[i].remove(element);
          }
        }
      }
    },
    getPointName(data) {
      if (data.length > 0) {
        var pointArray = {};
        data.forEach((a) => {
          pointArray[a.Project] = a.Project;
        });
        return pointArray;
      }
    },
    getColors(data) {
      if (data.length > 0) {
        var pointColors = {};
        data.forEach((a) => {
          pointColors[a] = this.colors[a] ? this.colors[a] : "";
        });
        return pointColors;
      }
    },

    drawBarChart() {
      if (this.chart) this.chart.destroy();

      this.chart = bb.generate({
        size: {
          height: 120,
        },
        data: {
          x: "x",
          columns: this.chartData,
          names: this.getPointName(this.tableData),
          colors: this.getColors(this.chartNames),
          type: bar(),
          groups: [this.chartNames],
          order: null,
        },
        bar: {
          width: {
            ratio: 0.7,
          },
        },
        axis: {
          rotated: true,
          y: {
            padding: {
              bottom: 10,
            },
            min: 0,
            tick: {
              outer: false,
            },
          },
          x: {
            type: "category",
            tick: {
              multiline: false,
              tooltip: true,
            },
            height: 70,
          },
        },
        point: {
          pattern: [
            "circle",
            "rectangle",
            "<polygon points='2.5 0 0 2.5 2.5 5 5 2.5 2.5 0'></polygon>",
            "<polygon points='2.5 0 0 5 5 5'></polygon>",
            "<g><circle cx='10' cy='10' r='10'></circle><rect x='5' y='5' width='10' height='10' style='fill:#fff'></rect></g>",
          ],
        },
        bindto: "#barChart",
      });
      this.clearCharts();
    },
    clearProjects() {
      this.selects.projectSelect = true;
      this.showBasketType = false;
      this.basketTypes = [];
      this.showBasketAndWave = false;
      this.showAllWaves = true;
      this.baskets = [];
      this.waves = [];
      this.selectedProjects = [];
      this.selectedBasketTypes = [];
      setTimeout(() => (this.selects.projectSelect = false), 1000);
    },
    clearAllWaves() {
      this.selects.waveSelect = true;
      this.showProjects = true;
      this.selectedWaves = [];
      setTimeout(() => (this.selects.waveSelect = false), 1000);
    },
    clearWaves() {
      this.selectedWaves = [];
      if (this.selectedBaskets.length > 0) {
        this.showWave = false;
      }
    },
    clearBaskets() {
      this.selectedBaskets = [];
    },
    clearFilters() {
      this.clearProjects();
      this.clearAllWaves();
      this.clearBaskets();
      this.recalculeteProgressData();
      this.clearTestData();
    },
    clearTestData() {
      this.includingTestData = false;
    },
    async toggleIncludeIgnoreDataForAnalysesAndExports() {
      this.clearProjects();
      this.clearAllWaves();
      this.clearBaskets();
      this.recalculeteProgressData();
      await this.getProjectFilters();
    },
    clearBasketType() {
      this.selects.basketTypeSelect = true;
      this.selectedBasketTypes = [];
      this.showBasketAndWave = false;
      this.baskets = [];
      this.waves = [];
      setTimeout(() => (this.selects.basketTypeSelect = false), 1000);
    },
    projectChanged() {
      this.selects.projectSelect = true;
      if (this.selectedProjects.length === 0) {
        this.showBasketType = false;
        this.basketTypes = [];
        this.showBasketAndWave = false;
        this.baskets = [];
        this.waves = [];
        this.showAllWaves = true;
      } else {
        var basketTypes = this.filters.projectBasketTypes.filter((a) =>
          this.selectedProjects.includes(a.projectId)
        );
        this.basketTypes = basketTypes.sort(this.ascSort("name"));
        this.showBasketType = true;
        this.showAllWaves = false;
      }
      setTimeout(() => (this.selects.projectSelect = false), 1000);
    },
    // projectChanged1($event) {
    //   if ($event.length === 0) {
    //     this.showBasketType = false;
    //     this.basketTypes = [];
    //     this.showBasketAndWave = false;
    //     this.baskets = [];
    //     this.waves = [];
    //   } else {
    //     var basketTypes = this.filters.projectBasketTypes.filter((a) =>
    //       $event.includes(a.projectId)
    //     );
    //     this.basketTypes = basketTypes.sort(this.ascSort("name"));
    //     this.showBasketType = true;
    //   }
    // },
    basketChanged() {
      if (this.selectedWaves.length > 0) {
        this.selectedBaskets.map((Id) =>
          this.baskets
            .filter((b) => b.id === Id)
            .map((x) =>
              this.selectedWaves.indexOf(x.projectWaveId) === -1
                ? this.selectedWaves.push(x.projectWaveId)
                : console.log("This waveId already exists")
            )
        );
      }
    },
    waveChanged() {
      this.selects.waveSelect = true;
      if (this.selectedWaves.length > 0) {
        this.showProjects = false;
      } else {
        this.showProjects = true;
      }
      setTimeout(() => (this.selects.waveSelect = false), 1000);
    },
    basketTypeChanged() {
      this.selects.basketTypeSelect = true;
      if (this.selectedBasketTypes.length === 0) {
        this.showBasketAndWave = false;
        this.baskets = [];
        this.uniqueWaveNames = [];
      } else {
        var baskets = this.filters.projectBaskets.filter((a) =>
          this.selectedBasketTypes.includes(a.projectBasketTypeId)
        );
        var waves = this.filters.projectWaves.filter((a) =>
          this.selectedProjects.includes(a.projectId)
        );
        this.baskets = baskets.sort(this.descSort("startTime"));
        this.waves = waves.sort(this.descSort("startTime"));

        this.uniqueWavesObject = {};
        for (let wave of this.waves) {
          if (!this.uniqueWavesObject[wave.name]) {
            this.uniqueWavesObject[wave.name] = [];
            this.uniqueWaveNames.push(wave.name);
          }
          this.uniqueWavesObject[wave.name].push(wave.id);
        }

        this.showBasketAndWave = true;
      }
      setTimeout(() => (this.selects.basketTypeSelect = false), 1000);
    },
    basketTypeChanged1($event) {
      if ($event.length === 0) {
        this.showBasketAndWave = false;
        this.baskets = [];
        this.waves = [];
      } else {
        var baskets = this.filters.projectBaskets.filter((a) =>
          $event.includes(a.projectBasketTypeId)
        );
        var waves = this.filters.projectWaves.filter((a) =>
          this.selectedProjects.includes(a.projectId)
        );
        this.baskets = baskets.sort(this.descSort("startTime"));
        this.waves = waves.sort(this.descSort("startTime"));
        this.showBasketAndWave = true;
      }
    },
    ascSort(property) {
      return function(a, b) {
        if (a[property] < b[property]) {
          return -1;
        }
        if (a[property] > b[property]) {
          return 1;
        }
        return 0;
      };
    },
    descSort(property) {
      return function(a, b) {
        if (a[property] < b[property]) {
          return 1;
        }
        if (a[property] > b[property]) {
          return -1;
        }
        return 0;
      };
    },
    async getProjectFilters() {
      try {
        this.ADD_ASYNC_REQUEST();
        console.log("includingTestData: " + this.includingTestData);
        let url = baseUrl + `api/MonitoringSystem`;
        let queryDto = {
          includeIgnoreDataForAnalysesAndExports: this.includingTestData,
        };
        let filters = await axios.post(url, queryDto);
        this.filters = filters.data;
        this.projects = filters.data.projects.sort(this.ascSort("name"));
        this.allWaves = filters.data.projectWaves.sort(
          this.descSort("startTime")
        );

        this.allUniqueWavesObject = {};
        for (let wave of this.allWaves) {
          if (!this.allUniqueWavesObject[wave.name]) {
            this.allUniqueWavesObject[wave.name] = [];
            this.allUniqueWaveNames.push(wave.name);
          }
          this.allUniqueWavesObject[wave.name].push(wave.id);
        }
      } catch (e) {
        /* eslint-disable no-console */
        if (e !== 401) {
          this.showErrorDialog(e.response ? e.response.data.error : e);
        }
        console.error("Unable to get filters", e);
      } finally {
        this.REMOVE_ASYNC_REQUEST();
      }
    },
    async exportCsvfile(exportType) {
      let accept = exportType
        ? "text/csv"
        : "application/vnd.openxmlformats-officedocument" +
          ".spreadsheetml.sheet";
      let csvOrXlsx = exportType ? "Export.csv" : "Export.xlsx";
      try {
        var config = {
          responseType: "blob",
          headers: {
            Accept: accept,
          },
        };
        this.ADD_ASYNC_REQUEST();
        let url = baseUrl + `api/MonitoringSystem/export`;
        let queryDto = {
          projectIds: this.selectedProjects,
          basketIds: this.selectedBaskets,
          basketTypeIds: this.selectedBasketTypes,
          waveIds: this.getSelectedWaveIds(),
          exportType: exportType,
          includeIgnoreDataForAnalysesAndExports: this.includingTestData,
        };
        await axios.post(url, queryDto, config).then((response) => {
          fileDownload(response.data, csvOrXlsx);
        });
      } catch (e) {
        /* eslint-disable no-console */
        this.loading = false;
        if (e !== 401) {
          this.showErrorDialog(e.response ? e.response.data.error : e);
        }
      } finally {
        this.REMOVE_ASYNC_REQUEST();
      }
    },
    getSelectedWaveIds() {
      var self = this;
      let ids = [];
      this.selectedWaves.forEach((waveName) => {
        if (self.allUniqueWavesObject[waveName]) {
          ids = ids.concat(self.allUniqueWavesObject[waveName]);
        }
      });
      return ids;
    },
    async getFieldworkMonitoringSystemProgress() {
      try {
        this.ADD_ASYNC_REQUEST();
        const { itemsPerPage, page } = this.options;
        let topAndSkip =
          itemsPerPage === -1 || disabledServerSidePaging
            ? ""
            : `&$top=${itemsPerPage}&$skip=${(page - 1) * itemsPerPage}`;

        let oDataUrl =
          baseUrl + `odata/auditorBatches?$count=true${topAndSkip}`;
        // let url = baseUrl + `api/MonitoringSystem/sync-batch-data`;

        let queryDto = {
          projectIds: this.selectedProjects,
          basketIds: this.selectedBaskets,
          basketTypeIds: this.selectedBasketTypes,
          waveIds: this.getSelectedWaveIds(),
          includeIgnoreDataForAnalysesAndExports: this.includingTestData,
        };

        let progressData = await axios.post(oDataUrl, queryDto);
        this.progressData = progressData.data;
        this.totalBatches = progressData.data["@odata.count"];

        this.progressData.map((a) => {
          return (
            (a.cF_CompletedAt =
              a.cF_CompletedAt !== null
                ? new Date(a.cF_CompletedAt).toLocaleDateString()
                : "NOT COMPLETED"),
            (a.cF_CompletedBy =
              a.cF_CompletedBy !== null ? a.cF_CompletedBy : ""),
            (a.serverActivatedAt =
              a.serverActivatedAt !== null
                ? new Date(a.serverActivatedAt).toLocaleDateString()
                : ""),
            (a.serverLastGeneratedOrUpdatedAt =
              a.serverLastGeneratedOrUpdatedAt !== null
                ? new Date(
                    a.serverLastGeneratedOrUpdatedAt
                  ).toLocaleDateString()
                : ""),
            (a.serverCompletedAt =
              a.serverCompletedAt !== null
                ? new Date(a.serverCompletedAt).toLocaleDateString()
                : "NOT COMPLETED"),
            (a.serverClosedAt =
              a.serverClosedAt !== null
                ? new Date(a.serverClosedAt).toLocaleDateString()
                : "NOT CLOSED")
          );
        });
        this.lastTimeSynchronizedAt = new Date();
      } catch (e) {
        /* eslint-disable no-console */

        if (e !== 401) {
          this.showErrorDialog(e.response ? e.response.data.error : e);
        }
        console.error("Unable to get filters", e);
      } finally {
        this.REMOVE_ASYNC_REQUEST();
      }
    },
    updateTableRaw(raw) {
      (raw.cF_CompletedAt =
        raw.cF_CompletedAt !== null
          ? new Date(raw.cF_CompletedAt).toLocaleDateString()
          : "NOT COMPLETED"),
        (raw.cF_CompletedBy =
          raw.cF_CompletedBy !== null ? raw.cF_CompletedBy : ""),
        (raw.serverActivatedAt =
          raw.serverActivatedAt !== null
            ? new Date(raw.serverActivatedAt).toLocaleDateString()
            : ""),
        (raw.serverLastGeneratedOrUpdatedAt =
          raw.serverLastGeneratedOrUpdatedAt !== null
            ? new Date(raw.serverLastGeneratedOrUpdatedAt).toLocaleDateString()
            : ""),
        (raw.serverCompletedAt =
          raw.serverCompletedAt !== null
            ? new Date(raw.serverCompletedAt).toLocaleDateString()
            : "NOT COMPLETED"),
        (raw.serverClosedAt =
          raw.serverClosedAt !== null
            ? new Date(raw.serverClosedAt).toLocaleDateString()
            : "NOT CLOSED");
      let rawToUpdate = this.progressData.findIndex((a) => a.id === raw.id);
      this.progressData.splice(rawToUpdate, 1, raw);
    },
    calculateDataAndDrawChart() {
      this.chartData = [];
      this.chartNames = [];
      //let baskets = data.filter((el) => el.Basket === newValue);
      // this.tableData = baskets;

      let countOfTasksCompleted = this.progressData
        .map((b) => b.completedTasksCount)
        .reduce((a, c) => {
          return a + c;
        });
      let countOfTasksSkipped = this.progressData
        .map((b) => b.skippedTasksCount)
        .reduce((a, c) => {
          return a + c;
        });
      let countOfTasksIncomplete = this.progressData
        .map((b) => b.incompleteTasksCount)
        .reduce((a, c) => {
          return a + c;
        });
      this.chartNames.push(
        "Tasks Completed",
        "Tasks Skipped",
        "Tasks Incomplete"
      );

      this.chartData.push(["x", "selects"]);
      this.chartData.push(["Tasks Completed", countOfTasksCompleted]);
      this.chartData.push(["Tasks Skipped", countOfTasksSkipped]);
      this.chartData.push(["Tasks Incomplete", countOfTasksIncomplete]);

      this.drawBarChart();
    },
    async recalculeteProgressData() {
      await this.getFieldworkMonitoringSystemProgress();
      if (this.progressData.length > 0) {
        this.calculateDataAndDrawChart();
      }
    },
  },

  watch: {
    selectedBaskets(newValue) {
      if (newValue.length > 0 && this.selectedWaves.length == 0) {
        this.showWave = false;
      } else {
        this.showWave = true;
      }
    },
    options: {
      async handler(newValue, oldValue) {
        /* eslint-disable no-debugger */
        if (newValue === oldValue) return;
        if (this.progressData) {
          await this.getFieldworkMonitoringSystemProgress();
        }
      },
      deep: true,
    },
    // selectedWaves(newValue){
    //   if(this.selectedBaskets.length>0 && newValue.length==0){
    //     this.showWave=false
    //   }
    //   else{
    //     this.showWave=true

    //   }
    // },
    /*  selectedBaskets(newValue) {
      if (newValue === null) return;
      this.chartData = [];
      this.chartNames = [];
      this.selectedProjects = null;
      let baskets = data.filter((el) => el.Basket === newValue);
      this.tableData = baskets;

      let countOfTasksCompleted = baskets
        .map((b) => b.TasksCompleted)
        .reduce((a, c) => {
          return a + c;
        });
      let countOfTasksSkipped = baskets
        .map((b) => b.TasksSkipped)
        .reduce((a, c) => {
          return a + c;
        });
      let countOfTasksIncomplete = baskets
        .map((b) => b.TasksIncomplete)
        .reduce((a, c) => {
          return a + c;
        });
      this.chartNames.push(
        "Tasks Completed",
        "Tasks Skipped",
        "Tasks Incomplete"
      );

      this.chartData.push(["x", newValue]);
      this.chartData.push(["Tasks Completed", countOfTasksCompleted]);
      this.chartData.push(["Tasks Skipped", countOfTasksSkipped]);
      this.chartData.push(["Tasks Incomplete", countOfTasksIncomplete]);

      this.drawBarChart();
    },
    selectedProjects(newValue) {
      if (newValue === null) return;
      this.chartData = [];
      this.chartNames = [];
      this.selectedBaskets = null;
      let projects = data.filter((el) => el.Project === newValue);
      this.tableData = projects;

      let countOfTasksCompleted = projects
        .map((b) => b.TasksCompleted)
        .reduce((a, c) => {
          return a + c;
        });
      let countOfTasksSkipped = projects
        .map((b) => b.TasksSkipped)
        .reduce((a, c) => {
          return a + c;
        });
      let countOfTasksIncomplete = projects
        .map((b) => b.TasksIncomplete)
        .reduce((a, c) => {
          return a + c;
        });
      this.chartNames.push(
        "Tasks Completed",
        "Tasks Skipped",
        "Tasks Incomplete"
      );

      this.chartData.push(["x", newValue]);

      this.chartData.push(["Tasks Completed", countOfTasksCompleted]);
      this.chartData.push(["Tasks Skipped", countOfTasksSkipped]);
      this.chartData.push(["Tasks Incomplete", countOfTasksIncomplete]);

      this.drawBarChart();
    }, */
  },
};
</script>
<style lang="scss" scoped>
.buttons {
  position: absolute;
  right: 20px;
  bottom: 3px;
}
.Okbtn {
  font-size: 0.75rem !important;
  min-width: 50px !important;
  width: 70px !important;
  height: 28px !important;
}
.btnGroup {
  max-width: 130px;
  margin-top: 10px;
}
</style>
