import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import store from "./store/store";
import axios from "axios";
/* eslint-disable no-debugger */
Vue.config.productionTip = false;

axios.interceptors.request.use(
  async (config) => {

    let token = await store.dispatch("getAccessToken");

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  async (response)=>{
    
    return response;
  }, async (error) =>{
    
    if(error.response.status === 401){
      await store.dispatch("silentSignIn");
      return Promise.reject(401)
    }
    //else{
      return Promise.reject(error);
  
  }
)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
