/* eslint-disable no-console */
/* eslint-disable no-undef */
/* eslint-disable no-debugger */
import { UserManager, WebStorageStateStore } from "oidc-client";
export default class AuthService {
  constructor() {
    const STS_DOMAIN = process.env.VUE_APP_SERVER_IDENTITY_URL;
    const appBaseUrl = window.location.origin;

    const settings = {
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      authority: STS_DOMAIN,
      client_id: process.env.VUE_APP_CLIENT_ID,
      redirect_uri: appBaseUrl + "/callback.html",
      popup_redirect_uri: appBaseUrl + "/popup-callback.html",
      automaticSilentRenew: true,
      silent_redirect_uri: appBaseUrl + "/silent-renew.html",
      response_type: "code",
      scope: "openid profile fieldMonitoringApi1",
      post_logout_redirect_uri: appBaseUrl + "/",
      filterProtocolClaims: true
    };
    this.userManager = new UserManager(settings);
  }
  renewToken() {
    return this.userManager.signinSilent({
      data: {
        redirect_url: appBaseUrl + "/silent-renew.html"
      }
    });
  }
  async getUser() {
    return await this.userManager.getUser();
  }
  signInSilent() {
    return this.userManager.signinSilent();
  }
  async loginRedirect() {

    return await this.userManager.signinRedirect();
  }
  async loginPopup() {
    return await this.userManager.signinPopup();
  }
  async logout() {
    return await this.userManager.signoutRedirect();
  }
  async getAccessToken() {
    const data = await this.userManager.getUser();
    if (data) return data.access_token;
    else return undefined;
  }
}
