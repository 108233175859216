let baseUrl = process.env.VUE_APP_SERVER_IDENTITY_URL;
let countryUrl = baseUrl + `/api/countries`;;

import axios from "axios";
const state = {
   
    countries:[]
  };
  const mutations = {
    SET_COUNTRIES_LIST: (state, { countries }) => {
      state.countries = countries;
    },
  
  };
  const actions = {
    async getCountriesList(context) {
        try {
          context.commit("ADD_ASYNC_REQUEST");
          let token = await context.dispatch("getAccessToken");
          let axiosConfig = { headers: { Authorization: "Bearer " + token } };
          let countries = await axios.get(countryUrl, axiosConfig);
          context.commit("SET_COUNTRIES_LIST", { countries: countries.data });
          return "successful";
        } catch (e) {
          console.error("Unable to get countries:", e);
          return e;
        } finally {
          context.commit("REMOVE_ASYNC_REQUEST");
        }
      }
  };
  const getters = {
    countries: state => state.countries
  };
  export default {
    state,
    mutations,
    actions,
    getters
  };