import Vue from "vue";
import Vuex from "vuex";
import messagingStore from "./modules/messagingStore";
import auth from "./modules/auth";
import country from "./modules/country";
Vue.use(Vuex);

var store = new Vuex.Store({
    modules: {
        auth,
        messagingStore,
        country
    }
});

export default store;
