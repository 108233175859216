<template>
  <v-dialog
    v-model="showDialog"
    transition="dialog-bottom-transition"
    max-width="600"
    persistent
  >
    <template>
      <v-card>
        <div class="text-center pt-5">BATCH</div>
        <v-card-text>
          <v-row justify="center" class="text-center pb-5">
            <v-col cols="12" v-if="batchDetails"
              >{{ batchDetails.projectName }} - {{ batchDetails.waveName }} -
              {{ batchDetails.basketName }} -
              {{ batchDetails.storeName }}</v-col
            >
          </v-row>
          <v-card outlined>
            <v-card-text>
              <v-select
                v-model="selectedBatchOutcome"
                :items="batchOutcomeOptions"
                item-text="text"
                item-value="value"
                outlined
                dense
                label="Please choose the batch outcome"
              ></v-select>
              <v-text-field
                v-model="additionalNote"
                label="Additional note"
                outlined
                required
                @input="$v.additionalNote.$touch()"
                @blur="$v.additionalNote.$touch()"
                dense
                :error-messages="additionalNoteErrors"
              ></v-text-field>
              <p style="color:red;" v-if="submitStatus === 'ERROR'">
                Please provide additional note.
              </p>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            type="submit"
            @click="saveBatch"
            :disabled="submitStatus === 'PENDING'"
            >Ok</v-btn
          >
          <v-btn text @click="closeDialog">Cancel</v-btn>
        </v-card-actions>
        <v-card-actions
          :error-messages="additionalNoteErrors"
          class="justify-end"
        >
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
//const additionalNoteRequired = "Additional Note is required";
const minLengthError = "Min characters 5";
import axios from "axios";

const baseUrl = process.env.VUE_APP_API_URL;
export default {
  name: "Review",
  props: ["batchDetails", "method"],
  data() {
    return {
      selectedBatchOutcome: this.getFormatedreviewStatus,
      additionalNote: this.getNote,
      submitStatus: null,
      batchOutcomeOptions: [
        { text: "FULFILLED / COMPLETED", value: 0 },
        { text: "WORK in PROGRESS ", value: 5 },
        {
          text: "UNFULFILLED / INCOMPLETE DUE TO STORE UNAVAILABILITY",
          value: 10,
        },
        {
          text: "UNFULFILLED / INCOMPLETE DUE TO FIELD UNAVAILABILITY",
          value: 50,
        },
        { text: "OTHER ", value: 100 },
      ],
      //rules: [ value => (value && value >= 5) || 'Min 5 characters']
    };
  },
  validations() {
    if (this.selectedBatchOutcome == 100) {
      return {
        additionalNote: { required, minLength: minLength(5) },
      };
    } else {
      return {
        additionalNote: { minLength: minLength(5) },
      };
    }
  },
  computed: {
    ...mapGetters(["review", "showDialog", "user"]),

    additionalNoteErrors() {
      let errors = [];
      if (!this.$v.additionalNote.required && this.$v.additionalNote.$invalid) {
        errors = [];
        errors.push(minLengthError);
      }
      return errors;
    },
    getNote() {
      let note = "";
      if (this.batchDetails != null) {
        this.batchDetails.reviewNote
          ? (note = this.batchDetails.reviewNote)
          : "";
      }
      return note;
    },
    getFormatedreviewStatus() {
      let status = 0;
      if (this.batchDetails != null) {
        this.batchDetails.reviewStatus === "FULLFILLED"
          ? (status = 0)
          : this.batchDetails.reviewStatus === "WIP"
          ? (status = 5)
          : this.batchDetails.reviewStatus === "N/A - STORE"
          ? (status = 10)
          : this.batchDetails.reviewStatus === "N/A - FIELD"
          ? (status = 50)
          : this.batchDetails.reviewStatus === "OTHER"
          ? (status = 100)
          : 0;
      }

      return status;
    },
  },
  methods: {
    ...mapMutations([
      "SET_REVIEW",
      "SET_SHOW_DIALOG",
      "SET_SHOW_ERROR_MESSAGE",
      "ADD_ASYNC_REQUEST",
      "REMOVE_ASYNC_REQUEST",
    ]),

    closeDialog() {
      this.$v.$reset();
      this.selectedBatchOutcome = this.getFormatedreviewStatus;
      this.additionalNote =
        this.batchDetails.reviewNote != "" ? this.batchDetails.reviewNote : "";
      this.submitStatus = null;
      this.SET_SHOW_DIALOG({ showDialog: false });
      //this.batchToEdit = null;
    },
    async saveBatch() {
      this.$v.$touch();
      let reviewDto = {};
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        try {
          reviewDto = {
            id: this.batchDetails.id,
            reviewStatus: this.selectedBatchOutcome,
            reviewNote: this.additionalNote,
            reviewedBy: this.user.profile.given_name,
            projectId: this.batchDetails.projectId,
          };
          this.closeDialog();
          this.ADD_ASYNC_REQUEST();
          let url = baseUrl + `api/MonitoringSystem/review`;
          var result = await axios.post(url, reviewDto);
          this.method(result.data);
        } catch (e) {
          /* eslint-disable no-console */
          this.closeDialog();

          this.showErrorDialog(e.response ? e.response.data.error : e);
          console.error("Unable to update ", e);
        } finally {
          this.submitStatus = "PENDING";
          setTimeout(() => {
            this.submitStatus = "OK";
            //window.location.reload()
          }, 1000);
        }
        this.REMOVE_ASYNC_REQUEST();
      }

      //this.SET_SHOW_DIALOG({showDialog:false})
    },
    showErrorDialog(message) {
      this.SET_SHOW_ERROR_MESSAGE({
        value: true,
        message: message,
      });
    },
  },
  mounted() {
    this.SET_REVIEW({
      review: {
        reviewStatus: this.selectedBatchOutcome,
        reviewNote: this.additionalNote,
      },
    });
  },
  watch: {
    batchDetails(newValue, oldValue) {
      if (newValue === oldValue) return;
      this.selectedBatchOutcome = this.getFormatedreviewStatus;
      this.additionalNote = this.getNote;
    },
    //   selectedBatchOutcome(){
    //      if(this.selectedBatchOutcome==100){
    //    this.validations={
    //   additionalNote: { required ,minLength: minLength(5)}
    // }
    //  }
    //  else{
    //      this.validations={
    //   additionalNote: {minLength: minLength(5)}
    // }
    //  }
    //   }
  },
};
</script>
