import Vue from "vue";
import VueRouter from "vue-router";
import Report from "../views/report.vue";
import Users from "../views/Users.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/report",
    name: "Report",
    component: Report
  },
  {
    path: "/users",
    name: "users",
    component: Users
  }
];

const router = new VueRouter({
  routes
});

router.push("report");

export default router;
