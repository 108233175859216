/* eslint-disable no-console */
/*eslint no-prototype-builtins: "error"*/
import AuthService from "../../services/authService";
let baseUrl = process.env.VUE_APP_SERVER_IDENTITY_URL;
let url = baseUrl + "/api/users";
let vendorUrl = baseUrl + "/api/vendors";
const auth = new AuthService();

import axios from "axios";

const state = {
  isLoggedIn: false,
  isAdmin: false,
  isCountryAdmin: false,
  user: null,
  users: [],
  vendors:[],
  countryId:null
};
const mutations = {
  SET_USER: (state, { user }) => {
    state.user = user;
    if (user) {
      state.isLoggedIn = true;
      let hasProperty = {}.hasOwnProperty.call(user.profile, "cglobadmin");
      if (hasProperty && user.profile["cglobadmin"] === "1") {
        state.isAdmin = true;
      } else {
        state.isAdmin = false;
      }

      let hasCountryProperty = {}.hasOwnProperty.call(user.profile, "ccntadmin");
      if (hasCountryProperty && user.profile["ccntadmin"] === "1") {
        state.isCountryAdmin = true;
      } else {
        state.isCountryAdmin = false;
      }
      let hasCountryIdProperty = {}.hasOwnProperty.call(user.profile, "c-c");
      if (hasCountryIdProperty ) {
        state.countryId = user.profile["c-c"];
      } 
    }
  },
  ADD_CREATED_USER_TO_THE_LIST: (state, { createdUser }) => {
    state.users.push(createdUser);
  },
  SET_USERS_LIST: (state, { users }) => {
    state.users = users;
  },
  SET_VENDORS_LIST: (state, { vendors }) => {
    state.vendors = vendors;
  },
  DELETE_USER: (state, { userId }) => {
    let userToDelete = state.users.find(a => a.id === userId);
    if (userToDelete) {
      state.users.splice(state.users.indexOf(userToDelete), 1);
    }
  },
  UPDATE_USER: (state, { updatedUser }) => {
    let userToUpdate = state.users.find(a => a.id === updatedUser.id);
    if (userToUpdate) {
      state.users.splice(state.users.indexOf(userToUpdate), 1, updatedUser);
    }
  }
};
const actions = {
  async getUserInformation(context) {
    try {
      let user = await auth.getUser();
      context.commit("SET_USER", { user: user });
      console.log("user", user);
    } catch (e) {
      console.error("Failed to get user info:", e);
    }
  },
  async loginRedirect() {
    try {
      await auth.loginRedirect();
    } catch (e) {
      console.error("Failed to login:", e);
    }
  },
  async silentSignIn() {
    try {
      await auth.signInSilent();
    } catch (e) {
      console.error("Failed to sign in silent:", e);
      if (e.error == "login_required") {
        await auth.loginRedirect();
      }
    }
  },
  async logout() {
    try {
      await auth.logout();
    } catch (e) {
      console.error("Failed to logout:", e);
    }
  },
  async getAccessToken() {
    try {
      return await auth.getAccessToken();
    } catch (error) {
      console.error("Unable to get access token - ", error);
    }
  },
  async createUser(context, { userToCreate }) {
    try {
      context.commit("ADD_ASYNC_REQUEST");
      let token = await context.dispatch("getAccessToken");
      let axiosConfig = { headers: { Authorization: "Bearer " + token } };
      let createdUser = await axios.post(url, userToCreate, axiosConfig);
      context.commit("ADD_CREATED_USER_TO_THE_LIST", {
        createdUser: createdUser.data
      });
      return "successful";
    } catch (e) {
      console.error("Unable to create user:", e);
      return e;
    } finally {
      context.commit("REMOVE_ASYNC_REQUEST");
    }
  },
  async updateUser(context, { userToUpdate, userId }) {
    try {
      context.commit("ADD_ASYNC_REQUEST");
      let token = await context.dispatch("getAccessToken");
      let axiosConfig = { headers: { Authorization: "Bearer " + token } };
      await axios.put(url + "/" + userId, userToUpdate, axiosConfig);
      userToUpdate.id = userId;
      context.commit("UPDATE_USER", { updatedUser: userToUpdate });
      return "successful";
    } catch (e) {
      console.error("Unable to update user:", e);
      return e;
    } finally {
      context.commit("REMOVE_ASYNC_REQUEST");
    }
  },
  async deleteUser(context, { userId }) {
    try {
      context.commit("ADD_ASYNC_REQUEST");
      let token = await context.dispatch("getAccessToken");
      let axiosConfig = { headers: { Authorization: "Bearer " + token } };
      await axios.delete(url + "/" + userId, axiosConfig);
      context.commit("DELETE_USER", { userId: userId });
      return "successful";
    } catch (e) {
      console.error("Unable to delete user:", e);
      return e;
    } finally {
      context.commit("REMOVE_ASYNC_REQUEST");
    }
  },
  async getUsersList(context) {
    try {
      context.commit("ADD_ASYNC_REQUEST");
      let token = await context.dispatch("getAccessToken");
      let axiosConfig = { headers: { Authorization: "Bearer " + token } };
      let users = await axios.get(url, axiosConfig);
      context.commit("SET_USERS_LIST", { users: users.data });
      return "successful";
    } catch (e) {
      console.error("Unable to get users:", e);
      return e;
    } finally {
      context.commit("REMOVE_ASYNC_REQUEST");
    }
  },
  async getVendorsList(context) {
    try {
      context.commit("ADD_ASYNC_REQUEST");
      let token = await context.dispatch("getAccessToken");
      let axiosConfig = { headers: { Authorization: "Bearer " + token } };
      let vendors = await axios.get(vendorUrl, axiosConfig);
      context.commit("SET_VENDORS_LIST", { vendors: vendors.data });
      return "successful";
    } catch (e) {
      console.error("Unable to get vendors:", e);
      return e;
    } finally {
      context.commit("REMOVE_ASYNC_REQUEST");
    }
  }
};

const getters = {
  user: state => state.user,
  isAdmin: state => state.isAdmin,
  isCountryAdmin: state => state.isCountryAdmin,
  users: state => state.users,
  isLoggedIn: state => state.isLoggedIn,
  vendors: state => state.vendors,
  countryId: state => state.countryId,
  
};
export default {
  state,
  mutations,
  actions,
  getters
};
