<template>
  <v-container>
    <v-row justify-center>
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">User Profile</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col xs="12" sm="12" md="12">
                  <v-alert
                    :value="showDialogErrorMessage"
                    type="error"
                    
                    outlined
                    ><span v-html="errorMessage"></span
                  ></v-alert>
                </v-col>
                <v-col xs="12" sm="12" md="12">
                  <v-text-field
                    class="custom"
                    :label="labelEmail"
                    v-model.trim="$v.email.$model"
                    :hint="emailErrorMessage"
                    persistent-hint
                    :placeholder="labelEmail"
                    :error="$v.email.$anyError"
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col v-if="!update" xs="12" sm="12" md="12">
                  <v-text-field
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    @click:append="show1 = !show1"
                    class="custom"
                    :label="labelPassword"
                    v-model.trim="$v.password.$model"
                    :hint="passwordErrorMessage"
                    persistent-hint
                    :error="$v.password.$anyError"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col v-if="!update" xs="12" sm="12" md="12">
                  <v-text-field
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show2 ? 'text' : 'password'"
                    @click:append="show2 = !show2"
                    class="custom"
                    :label="labelConfirmPassword"
                    v-model.trim="$v.confirmPassword.$model"
                    :hint="confirmPasswordErrorMessage"
                    persistent-hint
                    :error="$v.confirmPassword.$anyError"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col xs="12" sm="12" md="12">
                  <v-select
                    :items="userRoles"
                    v-model.trim="$v.selectedRole.$model"
                    persistent-hint
                    :label="labelUserRole"
                    :placeholder="labelUserRole"
                    class="custom"
                    item-text="label"
                    item-value="value"
                    :error="$v.selectedRole.$anyError"
                    outlined
                    dense
                  ></v-select>
                </v-col>
                  <v-col v-if="showCountry" xs="12" sm="12" md="12">
                  <v-select
                    v-model.trim="$v.selectedCountry.$model"
                    :hint="countryErrorMessage"
                    persistent-hint
                    class="custom"
                    :error="$v.selectedCountry.$anyError"
                    :items="countryIds"
                    label="Country"
                    item-text="countryLabel"
                    item-value="id"
                    outlined
                    dense
                  ></v-select>
                </v-col>
                <v-col v-if="showVendors" xs="12" sm="12" md="12">
                  <v-select
                    :items="vendors"
                     v-model.trim="$v.selectedVendors.$model"
                    persistent-hint
                    class="custom"
                    :error="$v.selectedVendors.$anyError"
                    label="Vendor"
                    item-text="vendorName"
                    item-value="id"
                    multiple
                    outlined
                    dense
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDialog()"
              >Close</v-btn
            >
            <v-btn
              depressed
              color="blue darken-1"
              @click="updateSelectedUser()"
              v-if="update"
              text
              >Update</v-btn
            >
            <v-btn
              depressed
              color="blue darken-1"
              @click="saveUser()"
              v-if="!update"
              text
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="deletiondialogIsvisible" persistent max-width="600px">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text>
            Are you suer you want to delete "{{ userEmailToDelete }}" user?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="deleteSelectedUser()"
              >Yes</v-btn
            >
            <v-btn
              color="blue darken-1"
              @click="cancelDeletionAndResetselectedUser()"
              text
              >No</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row class="justify-center">
      <v-col md="12">
        <v-alert
          :value="errorMessage.length > 0 && showErrorMessage"
          type="error"
          dismissible
          outlined
          ><span v-html="errorMessage"></span>
        </v-alert>
        <v-btn
          slot="activator"
          depressed
          small
          class="mb-3"
          color="red"
          dark
          @click="showForm(null)"
          >Add new user</v-btn
        >
        <div>
          <v-data-table
            text
            :headers="headers"
            v-if="users"
            :items="vendorsName"
            :loading="true"
          >
            <v-progress-linear
              slot="progress"
              color="blue"
              :indeterminate="activeAsyncRequest > 0"
            ></v-progress-linear>
            <template v-slot:[`item.userName`]="{ item }">
              <td class="text-xs-left">{{ item.userName }}</td>
            </template>
            <template v-slot:[`item.userType`]="{ item }">
              <td class="text-xs-left">
                {{ item.userType}}
              </td>
            </template>
            <template v-slot:[`item.vendorName`]="{ item }">
              <td
                v-for="(name, index) in item.vendorName"
                :key="index"
                class="text-xs-left"
              >
                {{ index == 0 ? name : ", " + name }}
              </td>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <td class="text-xs-left">
                <v-btn depressed small @click="showForm(item)" class="mr-1"
                  >Update</v-btn
                >
                <v-btn
                  depressed
                  small
                  @click="showDeletionWarningDialog(item.id, item.userName)"
                  >Delete user</v-btn
                >
              </td>
            </template>
          </v-data-table>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
var moment = require("moment");
import { mapGetters, mapActions } from "vuex";
import {
  email,
  required,
  sameAs,
  minLength,
  requiredIf,
} from "vuelidate/lib/validators";
const format = /[ !@#$%^&*()_+\-=\]{};':"\\|,.<>?]/;
const passwordContainsLetter = (password) => /[A-Z]/.test(password);
const passwordContainsNumber = (password) => /[0-9]/.test(password);
const passwordContainsSpecialCharacter = (password) => format.test(password);
export default {
  data() {
    return {
      showErrorMessage: false,
      showDialogErrorMessage: false,
      errorMessage: "",
      update: false,
      dialog: false,
      labelEmail: "Email*",
      labelPassword: "Password*",
      labelConfirmPassword: "Confirm Password*",
      labelUserRole: "User role*",
      userName: "",
      password: "",
      confirmPassword: "",
      email: "",
      userId: "",
      selectedRole: null,
      selectedVendors: [],
      selectedCountry:'',
      showPassword: false,
      deletiondialogIsvisible: false,
      userIdToDelete: null,
      userEmailToDelete: null,
      showVendors: true,
      showCountry:true,
      submitted: false,
      show1: false,
      show2: false,
      headers: [
        {
          text: "Name",
          align: "left",
          value: "userName",
        },
        {
          text: "User type",
          value: "userType",
        },
        { text: "Vendors", value: "vendorName" },
        { text: "Country Name", value: "countryName" },
        {
          text: "Action",
          value: "actions",
          sortable: false,
        },
      ],
      emailErrorMessage: null,
      passwordErrorMessage: null,
      confirmPasswordErrorMessage: null,
      countryErrorMessage:null
    };
  },
  validations() {
    return {
      email: { required, email },
      password: {
        required: requiredIf(function() {
          if (!this.update) {
            return true;
          }
          return false;
        }),
        passwordContainsLetter: passwordContainsLetter,
        passwordContainsNumber: passwordContainsNumber,
        passwordContainsSpecialCharacter: passwordContainsSpecialCharacter,
        minLength: minLength(8),
      },
      confirmPassword: {
        required: requiredIf(function() {
          if (!this.update) {
            return true;
          }
          return false;
        }),
        sameAsPassword: sameAs("password"),
      },
      selectedRole:{required},
      selectedCountry:{
        required:requiredIf(function() {
          if (this.selectedRole == "Admin") {
            return false;
          }
          return true;
        }),
    },
     selectedVendors:{
        required:requiredIf(function() {
          if (this.selectedRole == "Admin" || this.selectedRole == "Country Admin") {
            return false;
          }
          return true;
        }),
    }};
   
  },
  async mounted() {
    await this.getUsersList();
    await this.getVendorsList();
    await this.getCountriesList();
  },
  methods: {
    ...mapActions([
      "getUsersList",
      "getCountriesList",
      "createUser",
      "deleteUser",
      "updateUser",
      "getVendorsList",
    ]),
    moment(...args) {
      return moment(...args);
    },
    clearForm() {
      this.email = "";
      this.userId = "";
      this.password = "";
      this.confirmPassword="";
      this.selectedRole = null;
      this.selectedVendors = null;
      this.selectedCountry='';
      this.errorMessage = "";
      this.submitted = false;
      this.update = false;
      this.showVendors = true;
      this.showCountry = true;
      this.showDialogErrorMessage = false;
      this.$v.$reset();
    },
    showForm(userInfo) {
    
      if (userInfo) {
        let tempVendorIds = [];
        userInfo.associatedVendorIds.forEach((a) => {
          tempVendorIds.push(a.toUpperCase());
        });

        this.update = true;
        this.email = userInfo.userName;
        this.userId = userInfo.id;
        this.selectedRole = userInfo.userType;
        this.showVendors = true;
        this.showCountry = true;

        this.selectedVendors = tempVendorIds;
        this.selectedCountry = userInfo.countryId;
      } else {
        this.clearForm();
      }
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.clearForm();
    },
    showDeletionWarningDialog(id, email) {
      this.deletiondialogIsvisible = true;
      this.userIdToDelete = id;
      this.userEmailToDelete = email;
    },
    cancelDeletionAndResetselectedUser() {
      this.deletiondialogIsvisible = false;
      this.userIdToDelete = null;
      this.userEmailToDelete = null;
    },
    async deleteSelectedUser() {
      try {
        let result = await this.deleteUser({
          userId: this.userIdToDelete,
        });
        if (result && result != "successful") {
          this.errorMessage = result.response?.data?.error;
          this.showErrorMessage = true;
        }
      } catch (e) {
        console.error("Unable to delete user:", e);
      } finally {
        this.cancelDeletionAndResetselectedUser();
      }
    },
    validateUserForm() {
      /* eslint-disable no-debugger */
      this.$v.$touch();
      this.errorMessage = "";
      if (!this.$v.$anyError && this.selectedRole != null) {
        return true;
      } else {
        if (this.$v.email.$anyError) {
          this.errorMessage += "Email is not valid or empty.";
          this.showDialogErrorMessage = true;
        }
      
        if (this.$v.password.$anyError) {
          this.errorMessage +=  !this.$v.email.$anyError
              ? "Password is not valid or empty."
              : "<br/>" + "Password is not valid or empty.";
              this.showDialogErrorMessage = true;
        }
        if (this.$v.confirmPassword.$anyError) {
          this.errorMessage += !this.$v.password.$anyError && !this.$v.email.$anyError
            ? "Passwords must be identical."
            : "<br/>" + "Passwords must be identical.";
            this.showDialogErrorMessage = true;
        }
          if (this.selectedRole === null) {
          this.errorMessage += !this.$v.password.$anyError && !this.$v.email.$anyError && !this.$v.confirmPassword.$anyError
            ? " User role is not selected."
            : "<br/>" + " User role is not selected.";
          this.showDialogErrorMessage = true;
        }
         if (this.$v.selectedCountry.$anyError) {
          this.errorMessage += !this.$v.password.$anyError &&
           !this.$v.email.$anyError &&
            !this.$v.confirmPassword.$anyError && !this.$v.selectedRole.$anyError
            ? " Country is not selected."
            : "<br/>" + " Country is not selected.";
          this.showDialogErrorMessage = true;
        }
        if (this.$v.selectedVendors.$anyError) {
          this.errorMessage += !this.$v.password.$anyError &&
           !this.$v.email.$anyError &&
            !this.$v.confirmPassword.$anyError &&
             !this.$v.selectedRole.$anyError && !this.$v.selectedCountry.$anyError
            ? " Vendor is not selected."
            : "<br/>" + " Vendor is not selected.";
          this.showDialogErrorMessage = true;
        }
        return false;
      }
    },
    async saveUser() {
      if (!this.validateUserForm()) return;
      this.$v.$touch();
      this.submitted = true;
      let newUser;
     if(this.selectedRole==="Admin"){
       newUser = {
        userName: this.email,
        id: this.userId,
        isAdmin: true,
        isCountryAdmin:false,
        countryId:null,
        associatedVendorIds: null,
        newPassword:this.password
      };
        }
        else if(this.selectedRole==="Country Admin"){
 newUser = {
        userName: this.email,
        id: this.userId,
        isAdmin: false,
        isCountryAdmin:true,
        countryId:this.selectedCountry,
        associatedVendorIds: null,
        newPassword:this.password
       
      };
        }
        else{
           newUser = {
        userName: this.email,
        id: this.userId,
        isAdmin: false,
        isCountryAdmin:false,
        countryId:this.selectedCountry,
        associatedVendorIds: this.selectedVendors,
        newPassword:this.password
       
      };
        }
      try {
        let result = await this.createUser({ userToCreate: newUser });
        if (result && result != "successful") {
          this.errorMessage = result.response?.data?.error.Error;
          this.showDialogErrorMessage = true;
        } else {
          this.dialog = false;
          this.submitted = false;
          this.clearForm();
        }
      } catch (error) {
        console.error("from user state", error);
      }
    },
    async updateSelectedUser() {
      /* eslint-disable no-debugger */

      this.$v.$touch();
      this.submitted = true;
      let updatedUser;
        if(this.selectedRole==="Admin"){
       updatedUser = {
        userName: this.email,
        id: this.userId,
        isAdmin: true,
        isCountryAdmin:false,
        countryId:null,
        associatedVendorIds: this.selectedVendors,

      };
        }
        else if(this.selectedRole==="Country Admin"){
 updatedUser = {
        userName: this.email,
        id: this.userId,
        isAdmin: false,
        isCountryAdmin:true,
        countryId:this.selectedCountry,
        associatedVendorIds: this.selectedVendors,
       
      };
        }
        else{
           updatedUser = {
        userName: this.email,
        id: this.userId,
        isAdmin: false,
        isCountryAdmin:false,
        countryId:this.selectedCountry,
        associatedVendorIds: this.selectedVendors,
       
      };
        }
     
      try {
        let result = await this.updateUser({
          userToUpdate: updatedUser,
          userId: this.userId,
        });
        if (result && result != "successful") {
          this.errorMessage = result.response?.data?.error;
          this.showDialogErrorMessage = true;
        } else {
          this.dialog = false;
          this.submitted = false;
          this.clearForm();
        }
      } catch (e) {
        console.error("from user state", e);
      }
    },
  },
  computed: {
    ...mapGetters(["users", "activeAsyncRequest", "vendors","countries","countryId","isCountryAdmin"]),
    userRoles(){
      let userRoles =[]
   if(!this.isCountryAdmin){
       userRoles.push(
        { label: "Admin", value: "Admin" },
        { label: "Country Admin", value: "Country Admin" },
        { label: "Member", value: "Member" },
        
      ) 
   }
   else{
       userRoles.push(
         { label: "Country Admin", value: "Country Admin" },
        { label: "Member", value: "Member" },
        
      )
   }
   return userRoles;
    },
    countryIds(){
      let country = [];
      let element = [];
      if(this.countryId){
       element = this.countries.find((b) => b.id === this.countryId.toUpperCase());
       country.push(element)
          if (country) {
           return country
          }
      }
      return this.countries
      
    },
    vendorsName() {
      /* eslint-disable no-debugger */
      //debugger
      let tempVendorsName = [];
      let element = [];
      let names = [];
      let country = {};
      this.users.map((a) => {
        a.associatedVendorIds.forEach((c) => {
          element = this.vendors.find((b) => b.id === c.toUpperCase());
          if (element) {
            names.push(element.vendorName);
          }
        });
        if(a.countryId){
        country = this.countries.find((b) => b.id === a.countryId.toUpperCase());
        }
        tempVendorsName.push({
          associatedVendorIds: a.associatedVendorIds,
          vendorName: names,
          id: a.id,
          isAdmin: a.isAdmin,
          userType:a.isAdmin?"Admin":a.isCountryAdmin?"Country Admin":"Member",
          newPassword: a.newPassword,
          userName: a.userName,
          isCountryAdmin:a.isCountryAdmin,
          countryId:a.countryId?a.countryId.toUpperCase():'',
          countryName:country.countryLabel?country.countryLabel:''
        });
        names = [];
       country = [];

      });

      return tempVendorsName;
    },
  },
  watch: {
    email(newValue, oldValue) {
      if (newValue != oldValue && newValue != null) {
        let message = "";
        newValue.length < 1
          ? (message = "Email is required")
          : this.$v.email.$invalid
          ? (message = "Invalid e-mail.")
          : (message = "");
        this.emailErrorMessage = message;
      }
    },
    password(newValue) {
      //if (newValue != oldValue && newValue != null) {
        let message = "";
        newValue.length < 1
          ? (message = "Password is required")
          : !this.$v.password.passwordContainsNumber
          ? (message = "Passwords must have at least one digit ('0'-'9'). ")
          : !this.$v.password.passwordContainsLetter
          ? (message = "Passwords must have at least one uppercase ('A'-'Z'). ")
          : !this.$v.password.minLength
          ? (message = "Password must have at least 8 characters.")
          : !this.$v.password.passwordContainsSpecialCharacter
          ? (message =
              "Passwords must have at least one non alphanumeric character.")
          : (message = "");

        this.passwordErrorMessage = message;
     // }
    },
    confirmPassword(newValue, oldValue) {
      if (newValue != oldValue && newValue != null) {
        let message = "";
        newValue.length < 1
          ? (message = "Confirm Password is required")
          : !this.$v.confirmPassword.sameAsPassword
          ? (message = "Passwords must be identical.")
          : (message = "");

        this.confirmPasswordErrorMessage = message;
      }
    },
    selectedRole(newValue) {
      if (newValue=="Admin") {
        this.showCountry = false;

        (this.showVendors = false), (this.selectedVendors = []),this.selectedCountry='';
      } 
      else if(newValue=="Country Admin") {
        this.showVendors = false;
        (this.selectedVendors = [])
        this.showCountry = true;

      }
      else{
      this.showVendors = true;
        this.showCountry = true;
      }
    },
    
    
  },
};
</script>
<style lang="scss">
.custom {
  label {
    min-width: fit-content;
  }
}
.v-messages.theme--light {
  color: red !important;
}
</style>
