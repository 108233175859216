/* eslint-disable no-debugger */
const state = {
  errorMessage: "",
  showErrorMessage: false,
  activeAsyncRequest: 0,
  review: { reviewStatus: "", reviewNote: "" },
  showDialog: false,
  options: {},
};
const mutations = {
  SET_SHOW_ERROR_MESSAGE: (state, { value, message }) => {
    state.showErrorMessage = value;
    state.errorMessage = message;
  },
  ADD_ASYNC_REQUEST: (state) => {
    state.activeAsyncRequest++;
  },
  REMOVE_ASYNC_REQUEST: (state) => {
    state.activeAsyncRequest--;
  },
  SET_REVIEW: (state, { review }) => {
    state.review = review;
  },
  SET_SHOW_DIALOG: (state, { showDialog }) => {
    state.showDialog = showDialog;
  },
  SET_OPTIONS: (state, { options }) => {
    state.options = options;
  },
};
const getters = {
  errorMessage: (state) => state.errorMessage,
  showErrorMessage: (state) => state.showErrorMessage,
  activeAsyncRequest: (state) => state.activeAsyncRequest,
  review: (state) => state.review,
  showDialog: (state) => state.showDialog,
  options: (state) => state.options,
};

export default {
  state,
  mutations,
  getters,
};
