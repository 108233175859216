<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center"></div>
      <v-toolbar-items>
        <v-btn
          v-if="isLoggedIn"
          text
          to="/report"
          class="ml-5"
          v-text="'Reporting'"
        ></v-btn>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn
          text
          to="/users"
          v-if="ifuser && (isAdmin || isCountryAdmin)"
          v-text="'Users'"
        ></v-btn>
        <v-btn text @click="loginRedirect()" v-if="!isLoggedIn">Login </v-btn>
        <v-menu v-else bottom min-width="200px" rounded offset-y>
          <template v-slot:activator="{ on }">
            <v-btn icon x-large v-on="on">
              <v-avatar color="white" size="48">
                <span class="blue--text headline">{{
                  getInitials(user.profile)
                }}</span>
              </v-avatar>
            </v-btn>
          </template>
          <v-card>
            <v-list-item-content class="justify-center">
              <div class="mx-auto text-center">
                <v-avatar color="blue">
                  <span class="white--text headline">{{
                    getInitials(user.profile)
                  }}</span>
                </v-avatar>
                <h3>{{ user.profile.name }}</h3>
                <p class="caption mt-1">
                  {{ user.profile.email }}
                </p>
                <v-divider class="my-3"></v-divider>

                <v-btn depressed rounded text @click="logout">
                  logout
                </v-btn>
              </div>
            </v-list-item-content>
          </v-card>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>

    <v-main v-if="isLoggedIn"> <router-view></router-view> </v-main>
    <error-dialog></error-dialog>
    <v-overlay :value="activeAsyncRequest">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
/* eslint-disable no-debugger */
import ErrorDialog from "./components/ErrorDialog.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "App",
  components: { ErrorDialog },
  data: () => ({
    //
  }),
  async mounted() {
    await this.getUserInformation();
  },
  methods: {
    ...mapActions(["loginRedirect", "getUserInformation", "logout"]),
    getInitials(profile) {
      try {
        let names = "NA";
        if (Array.isArray(profile.name)) {
          names = profile.name[0].split(" ");
        } else if (typeof profile.name === "string") {
          names = profile.name.split(" ");
        }
        let initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
          initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
      } catch (error) {
        console.error(error);
        return "NA";
      }
    },
  },
  computed: {
    ...mapGetters(["user", "activeAsyncRequest","isAdmin","isLoggedIn","isCountryAdmin"]),
      ifuser() {
      if (this.user) {
        return true;
      } else {
        return false;
      }
    },
  }
};
</script>
