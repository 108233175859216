<template>
  <v-container fluid>
    <v-row class="text-center">
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Data
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :headers="headers"
            :items="getReviewStatus()"
            :search="search"
            :item-class="getColor"
            :items-per-page="50"
            :options.sync="options"
            :footer-props="{
              itemsPerPageOptions: [
                5,
                50,
                100,
                {
                  text: 'All',
                  value: -1,
                },
              ],
            }"
          >
            <template slot="headers" slot-scope="props">
              <tr>
                <th
                  v-for="(header, i) in props.headers"
                  :key="i"
                  v-html="header.text"
                  :width="header.width"
                ></th>
              </tr>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn depressed @click="reviewBatch(item)">Review</v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <review :method="updateTableRaw" :batchDetails="batchToEdit"></review>
  </v-container>
</template>

<script>
/* eslint-disable no-debugger */
//import * as d3 from "../../node_modules/d3-fetch";
import Review from "../components/ReviewDialog";
import { mapGetters, mapMutations } from "vuex";
//import axios from "axios";

//const baseUrl = process.env.VUE_APP_API_URL;

export default {
  name: "TableComponent",
  props: ["tableData", "updateTableRaw", "totalBatches"],
  components: { Review },
  data: () => ({
    search: "",
    batchToEdit: null,
    reviewStatus: null,
    sortDesc: true,
    sortBy: "serverCreatedAt",
    headers: [
      { text: "Basket", value: "basketName" },
      { text: "Wave", value: "waveName" },
      { text: "StoreId", value: "storeId" },
      { text: "Store", value: "storeName", width: "300" },
      { text: "Total Task", value: "totalTasksCount", align: "center" },
      { text: "Comp. Tasks", value: "completedTasksCount", align: "center" },
      { text: "Skipped Tasks", value: "skippedTasksCount", align: "center" },
      { text: "Incomp. Tasks", value: "incompleteTasksCount", align: "center" },
      { text: "", value: "actions", align: "center", sortable: false },
      { text: "Review Status", value: "reviewStatus" },

      { text: "Comp. Tasks W-1", value: "completed_W1", align: "center" },
      { text: "Comp. Tasks W-2", value: "completed_W2", align: "center" },
      { text: "Comp. Tasks W-3", value: "completed_W3", align: "center" },
      {
        text: "Auditor",
        value: "auditorName",
      },
      { text: "Project", value: "projectName" },
      { text: "Completed At", value: "cF_CompletedAt" },
      { text: "Completed By", value: "cF_CompletedBy" },
      { text: "Server Activated At", value: "serverActivatedAt" },
      {
        text: "Server Last Generated Or Updated At",
        value: "serverLastGeneratedOrUpdatedAt",
      },
      { text: "Server Completed At", value: "serverCompletedAt" },
      { text: "Server Closed At", value: "serverClosedAt" },
    ],
  }),
  computed: {
    ...mapGetters(["review", "user", "showDialog", "options"]),
    options: {
      set(options) {
        this.SET_OPTIONS({ options: options });
      },
      get() {
        return this.$store.state.options;
      },
    }
  },
  mounted() {
    if (!this.showDialog) {
      this.batchToEdit = null;
    }
  },

  methods: {
    ...mapMutations([
      "SET_OPTIONS",
      "SET_SHOW_ERROR_MESSAGE",
      "ADD_ASYNC_REQUEST",
      "REMOVE_ASYNC_REQUEST",
      "SET_SHOW_DIALOG",
    ]),

    getColor(item) {
      if (item.incompleteTasksCount === 0) return "green_row";
    },
    getReviewStatus() {
      let ReviewStatus = [];
      ReviewStatus = [
        ...new Set(
          this.tableData,
          this.tableData.map((f) =>
            f.reviewStatus === 0
              ? (f.reviewStatus = "FULLFILLED")
              : f.reviewStatus === 5
              ? (f.reviewStatus = "WIP")
              : f.reviewStatus === 10
              ? (f.reviewStatus = "N/A - STORE")
              : f.reviewStatus === 50
              ? (f.reviewStatus = "N/A - FIELD")
              : f.reviewStatus === 100
              ? (f.reviewStatus = "OTHER")
              : null
          )
        ),
      ];
      return ReviewStatus;
    },
    reviewBatch(batch) {
      this.batchToEdit = JSON.parse(JSON.stringify(batch));
      this.SET_SHOW_DIALOG({ showDialog: true });
    },
  },
};
</script>
<style>
.green_row {
  background-color: #76ff7696;
}
.green_row:hover {
  background: #32813296 !important;
}
</style>
