<template>
  <v-dialog
    transition="dialog-bottom-transition"
    persistent
    max-width="600"
    v-model="showErrorMessage"
  >
    <v-card>
      <v-toolbar color="black" class="mb-5" dark>Error</v-toolbar>
      <v-card-text> {{ errorMessage }} </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn text outlined @click="closeDialog()">Ok</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  computed: {
    ...mapGetters(["errorMessage", "showErrorMessage"]),
  },
  methods: {
    ...mapMutations(["SET_SHOW_ERROR_MESSAGE", "SET_ERROR_MESSAGE"]),
    closeDialog() {
      this.SET_SHOW_ERROR_MESSAGE({
        value: false,
        message: "",
      });
    },
  },
};
</script>
